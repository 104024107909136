<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/demand' }"
              >需方市场</el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618"
              >需方详情</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>

    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <section class="bgfff">
          <div class="dis align_center" style="padding: 30px">
            <div class="icon"></div>
            <div class="size_18 marl_20">{{ supply.demand_name }}</div>
          </div>
        </section>
      </div>
    </div>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <section class="bgfff">
          <div style="width: 1460px; border-radius: 6px">
            <div
              class="flex"
              style="
                justify-content: space-around;
                align-items: center;
                padding: 20px 0px;
              "
            >
              <div
                class="size_18"
                style="width: 300px; height: 30px; line-height: 30px"
              >
                需求类型：{{ supply.type_name }}
              </div>
              <div
                class="size_18"
                style="width: 300px; height: 30px; line-height: 30px"
              >
                行业类型:{{ supply.industry_name }}
              </div>
              <div
                class="size_18"
                style="width: 340px; height: 30px; line-height: 30px"
              >
                发布时间：{{ supply.create_time }}
              </div>
            </div>
            <div class="">
              <div
                class="flex"
                style="
                  justify-content: space-around;
                  align-items: center;
                  padding: 20px 0px;
                "
              >
                <div
                  class="size_18"
                  style="width: 300px; height: 30px; line-height: 30px"
                >
                  资金用途: {{ supply.purpose }}
                </div>
                <div
                  class="size_18"
                  style="width: 300px; height: 30px; line-height: 30px"
                >
                  是否有抵押物：
                  <span v-if="supply.is_collateral === 1">是</span>
                  <span v-else>否</span>
                </div>
                <div
                  class="size_18"
                  style="width: 340px; height: 30px; line-height: 30px"
                >
                  是否有担保：
                  <span v-if="supply.is_guarantee === 1">是</span>
                  <span v-else>否</span>
                </div>
              </div>
              <!-- <div
                class="flex"
                style="justify-content: space-around; align-items: center"
              >
                <div class="size_18">供货时限：{{ supply.days }}</div>
                <div class="size_18">联系人：{{ supply.contacts }}</div>
                <div class="size_18">电话：{{ supply.phone }}</div>
              </div> -->
              <div
                class="flex"
                style="
                  justify-content: space-around;
                  align-items: center;
                  padding: 20px 0px;
                "
              >
                <div
                  class="size_18"
                  style="width: 300px; height: 30px; line-height: 30px"
                >
                  贷款周期（天）：{{ supply.cycle }}
                </div>
                <div
                  class="size_18"
                  style="width: 300px; height: 30px; line-height: 30px"
                >
                  利率(%)：{{ supply.rate }}
                </div>
                <div
                  class="size_18"
                  style="width: 340px; height: 30px; line-height: 30px"
                >
                  响应时长：{{ supply.deadline }}
                </div>
              </div>
              <div
                class="flex"
                style="
                  justify-content: space-around;
                  align-items: center;
                  padding: 20px 0px;
                "
              >
                <div
                  class="size_18"
                  style="width: 300px; height: 30px; line-height: 30px"
                >
                  联系人：{{ supply.contacts }}
                </div>
                <div
                  class="size_18"
                  style="width: 300px; height: 30px; line-height: 30px"
                >
                  联系电话：{{ supply.phone }}
                </div>
                <div
                  class="size_18"
                  style="height: 30px; line-height: 30px"
                >
                
                企业名称：{{ supply.enterprise_name }}

              </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <section class="bgfff" style="padding: 30px">
          <div class="dis align_center">
            <div class="icon"></div>
            <div class="size_18 marl_20">需求描述</div>
          </div>

          <div style="background-color: #fafafa">
            <div class="max_width min_width">
              <section class="bgfff">
                <div
                  class="size_18"
                  style="padding: 30px 0px"
                  v-html="supply.content"
                ></div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
    <app-footer></app-footer>
    <!-- <div style="background-color: #fafafa; padding: 30px 0px">
        <div class="max_width min_width">
          <div class="presentationbox">
            <div class="pad_30">
              <div class="dis align_center">
                <div class="icon"></div>
                <div class="marl_20 size_18 bold h_40">产品介绍</div>
              </div>
              <div class="presentation" v-html="details.content"></div>
            </div>

            <div>
              <div class="informationbox">
                <div class="dis align_center pad_30">
                  <div class="icon"></div>
                  <div class="marl_20 size_18 bold h_40">服务机构信息</div>
                </div>
                <div class="flex" style="margin: 0px 30px 30px 30px">
                  <div v-if="servemessage.img">
                    <img :src="servemessage.img" class="serveimg" alt="" />
                  </div>

                  <div v-else>
                    <img
                      src="../../assets/image/topimg.png"
                      class="serveimg"
                      alt=""
                    />
                  </div>

                  <div class="" style="padding: 10px">
                    <div
                      class="size_22 bold"
                      style="line-height: 30px; padding: 10px 0px"
                    >
                      {{ servemessage.name }}
                    </div>
                    <div
                      class="size_18 bold"
                      style="line-height: 30px; padding: 10px 0px"
                    >
                      联系电话：{{ servemessage.mobile }}
                    </div>
                  </div>
                </div>
                <div style="text-align: center">
                  <el-button class="btn" @click="godetails()"
                    >服务机构详情</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
  </div>
</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: 0,
      details: [],
      id: 0,
      supply: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    console.log(this.$route.query.id, "this.$routr.query.id");
    this.demandlist();
  },
  methods: {
    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },

    // 获取需方列表
    demandlist() {
      let data = {
        token: localStorage.eleToken,
        id: this.id,
      };
      console.log(data, "data");
      this.$get("demand_lst_d", data).then((res) => {
        console.log(res, "res");
        this.supply = res.result;
      });
    },
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
};
</script>
<style scoped>

.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}
</style>